import { ToggleButton, ToggleButtonGroup } from "@mui/material";

interface Option {
  label: string;
  value: string;
}

interface PreferenceToggleButtonProps {
  options: Option[];
  value: string[];
  disabled?: boolean;
  onChange: (value: string[]) => void;
}

export function PreferenceToggleButton(props: PreferenceToggleButtonProps) {
  const { options, value, disabled, onChange } = props;
  const handleChange = (_event: React.MouseEvent<HTMLElement>, newValue: string[]) => {
    onChange(newValue);
  };

  return (
    <ToggleButtonGroup
      value={value}
      sx={{
        flexWrap: "wrap",
        gap: 1,
      }}
      disabled={disabled}
      onChange={handleChange}
    >
      {options.map((option) => (
        <ToggleButton
          key={option.value}
          value={option.value}
          sx={{
            height: "32px", // Match MUI Chip height
            "&.MuiToggleButtonGroup-grouped": {
              textTransform: "none",
              borderRadius: "16px !important", // Match MUI Chip border radius
              border: (theme) => `1px solid ${theme.palette.divider} !important`,
            },
            "&.Mui-selected": {
              backgroundColor: (theme) => theme.palette.primary.main,
              color: (theme) => theme.palette.primary.contrastText,
              "&:hover": {
                backgroundColor: (theme) => theme.palette.primary.main,
              },
            },
          }}
        >
          {option.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}
