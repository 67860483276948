import { ExternalLink, LoadingButton, Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";
import { green } from "@mui/material/colors";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { type Worker } from "@src/appV2/Worker/api/types";

import { useCreateOrGetPlacementCandidate } from "../api/useCreateOrGetPlacementCandidate";

interface OnboardingPlacementCandidateProps {
  placementCandidateId: string;
  worker: Worker;
}

export function OnboardingPlacementCandidate(props: OnboardingPlacementCandidateProps) {
  const { worker, placementCandidateId } = props;
  const { refetch: refetchPlacementCandidate, isRefetching: isRefetchingPlacementCandidate } =
    useCreateOrGetPlacementCandidate(
      { workerId: worker.userId },
      { enabled: isDefined(worker.userId) }
    );

  return (
    <Stack spacing={2} flex={1} justifyContent="start">
      <Text variant="body2" color={green[800]}>
        Take 30 seconds to tell workplaces why you joined this profession below. Workplaces are
        twice as likely to reach out to profiles that have videos
      </Text>

      <Stack direction="column" alignItems="center" justifyContent="center" spacing={8}>
        <ExternalLink
          to={`${environmentConfig.REACT_APP_VIDEOASK_UPLOAD_URL}#worker_id=${worker.userId}&contact_name=${worker.name}&contact_email=${worker.email}`}
          color="primary"
          sx={{ textDecoration: "underline" }}
          target="_blank"
          onClick={() => {
            logEvent(APP_V2_APP_EVENTS.PLACEMENT_CANDIDATE_VIDEOASK_VIA_BROWSER_CLICKED, {
              placementCandidateId,
            });
          }}
        >
          Tap here to upload the video via browser.
        </ExternalLink>
        <LoadingButton
          variant="text"
          size="small"
          isLoading={isRefetchingPlacementCandidate}
          onClick={async () => await refetchPlacementCandidate()}
        >
          <Text variant="caption" color="primary">
            Already uploaded the video? Tap here to refresh
          </Text>
        </LoadingButton>
      </Stack>
    </Stack>
  );
}
