import { ExternalLink, Text, useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { Button, Stack } from "@mui/material";
import { APP_V2_APP_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { type Worker } from "@src/appV2/Worker/api/types";

import { UploadCandidateRequirementsDialog } from "./UploadCandidateRequirementsDialog";

interface UploadedCandidateRequirementsProps {
  placementCandidateId: string;
  introductionUrl?: string;
  worker: Worker;
}

export function UploadedCandidateRequirements(props: UploadedCandidateRequirementsProps) {
  const { introductionUrl, worker, placementCandidateId } = props;
  const reuploadRequirementModalState = useModalState();
  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: "100%" }}
      >
        <Text variant="h4">Introduction Video</Text>

        <Stack direction="row" alignItems="center" spacing={1}>
          <Button
            startIcon={<EditRoundedIcon fontSize="small" />}
            variant="text"
            size="small"
            onClick={() => {
              logEvent(APP_V2_APP_EVENTS.PLACEMENT_CANDIDATE_REQUIREMENTS_REUPLOAD_CLICKED, {
                placementCandidateId,
              });
              reuploadRequirementModalState.openModal();
            }}
          >
            Edit
          </Button>
          {isDefined(introductionUrl) && <ExternalLink to={introductionUrl}>View</ExternalLink>}
        </Stack>
      </Stack>

      {isDefined(introductionUrl) && reuploadRequirementModalState.modalIsOpen && (
        <UploadCandidateRequirementsDialog
          placementCandidateId={placementCandidateId}
          modalState={reuploadRequirementModalState}
          worker={worker}
        />
      )}
    </>
  );
}
