import { Text } from "@clipboard-health/ui-react";
import { Stack, Switch } from "@mui/material";

import { SwitchIcon } from "./SwitchIcon";

interface PlacementCandidateSwitchProps {
  checked: boolean;
  isLoading: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export function PlacementCandidateSwitch(props: PlacementCandidateSwitchProps) {
  const { checked, isLoading, onChange } = props;
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
      <Text variant="h3">Enable permanent job opportunities</Text>
      <Switch
        aria-label="Enable permanent job opportunities"
        color="primary"
        checked={checked}
        icon={<SwitchIcon isLoading={isLoading} />}
        checkedIcon={<SwitchIcon isLoading={isLoading} />}
        onChange={onChange}
      />
    </Stack>
  );
}
