import { Skeleton, Stack } from "@mui/material";

export function PlacementCandidateSkeleton() {
  return (
    <Stack spacing={2} sx={{ p: 2 }} flex={1} aria-label="Loading placement candidate information">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Skeleton variant="text" width="60%" height={40} />
        <Skeleton variant="text" width={40} height={40} />
      </Stack>
      <Skeleton variant="rounded" height={100} />
      <Stack spacing={1}>
        <Skeleton variant="text" width="40%" />
        <Skeleton variant="text" width="80%" />
        <Skeleton variant="text" width="70%" />
      </Stack>
      <Skeleton variant="rounded" height={200} />
    </Stack>
  );
}
