import { type UseModalState } from "@clipboard-health/ui-react";
import { Box, DialogContent } from "@mui/material";
import { FullScreenDialog } from "@src/appV2/lib/Dialogs";
import { FullScreenDialogTitle } from "@src/appV2/lib/Dialogs/FullScreen/DialogTitle";
import { type Worker } from "@src/appV2/Worker/api/types";

import { OnboardingPlacementCandidate } from "./OnboardingPlacementCandidate";

interface UploadCandidateRequirementsDialogProps {
  modalState: UseModalState;
  worker: Worker;
  placementCandidateId: string;
}

export function UploadCandidateRequirementsDialog(props: UploadCandidateRequirementsDialogProps) {
  const { modalState, worker, placementCandidateId } = props;

  return (
    <FullScreenDialog modalState={modalState}>
      <FullScreenDialogTitle onClose={modalState.closeModal}>
        Upload Placement Requirements
      </FullScreenDialogTitle>
      <DialogContent>
        <Box sx={{ marginTop: 2 }}>
          <OnboardingPlacementCandidate
            placementCandidateId={placementCandidateId}
            worker={worker}
          />
        </Box>
      </DialogContent>
    </FullScreenDialog>
  );
}
