import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import WorkIcon from "@mui/icons-material/Work";
import { Stack } from "@mui/material";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { PullToRefresh } from "@src/appV2/lib/PullToRefresh/PullToRefresh";
import { type Worker } from "@src/appV2/Worker/api/types";
import { isEmpty } from "lodash";

import { APP_V2_APP_EVENTS, useToast } from "../../lib";
import { logEvent } from "../../lib/analytics";
import { useCreateOrGetPlacementCandidate } from "../api/useCreateOrGetPlacementCandidate";
import { useUpdatePlacementCandidate } from "../api/useUpdatePlacementCandidate";
import { type PlacementCandidate, PlacementCandidateStatus } from "../types";
import { ActivatedPlacementCandidate } from "./ActivatedPlacementCandidate";
import { JobPreferenceForm } from "./JobPreferenceForm";
import { OnboardingPlacementCandidate } from "./OnboardingPlacementCandidate";
import { PendingPlacementCandidate } from "./PendingPlacementCandidate";
import { PlacementCandidateSwitch } from "./PlacementCandidateSwitch";
import { UploadCandidateResume } from "./UploadCandidateResume";
import { UploadedCandidateRequirements } from "./UploadedCandidateRequirements";

interface PlacementCandidateOnboardingContainerProps {
  placementCandidate: PlacementCandidate;
  worker: Worker;
}

export function PlacementCandidateOnboardingContainer(
  props: PlacementCandidateOnboardingContainerProps
) {
  const { placementCandidate, worker } = props;
  const { showSuccessToast } = useToast();
  const { refetch: refetchPlacementCandidate } = useCreateOrGetPlacementCandidate(
    { workerId: worker.userId },
    { enabled: isDefined(worker.userId) }
  );
  useLogEffect(
    APP_V2_APP_EVENTS.PLACEMENT_CANDIDATE_PAGE_VIEWED,
    {
      placementCandidateId: placementCandidate?.id,
    },
    {
      enabled: isDefined(placementCandidate) && isDefined(placementCandidate.id),
    }
  );
  const { mutateAsync: updatePlacementCandidate, isLoading: isUpdatePlacementCandidateLoading } =
    useUpdatePlacementCandidate();
  const hasIntroductionUrl =
    isDefined(placementCandidate.introductionUrl) && !isEmpty(placementCandidate.introductionUrl);

  return (
    <>
      <PullToRefresh onRefresh={refetchPlacementCandidate} />
      <Stack sx={{ overflowY: "auto", p: 2, pb: 15 }} flex={1} spacing={2}>
        <Stack spacing={2} flex={1}>
          <PlacementCandidateSwitch
            checked={placementCandidate.status !== PlacementCandidateStatus.DISABLED}
            isLoading={isUpdatePlacementCandidateLoading}
            onChange={async (event) => {
              if (isUpdatePlacementCandidateLoading) {
                return;
              }

              logEvent(APP_V2_APP_EVENTS.PLACEMENT_CANDIDATE_STATUS_UPDATED, {
                placementCandidateId: placementCandidate.id,
                status: event.target.checked
                  ? PlacementCandidateStatus.ONBOARDING
                  : PlacementCandidateStatus.DISABLED,
              });

              await updatePlacementCandidate({
                placementCandidateId: placementCandidate.id,
                status: event.target.checked
                  ? PlacementCandidateStatus.ONBOARDING
                  : PlacementCandidateStatus.DISABLED,
                workerId: worker.userId,
              });
              await refetchPlacementCandidate();
            }}
          />

          {placementCandidate.status === PlacementCandidateStatus.DISABLED && (
            <Stack flex={1} justifyContent="center" alignItems="center" spacing={0.5}>
              <WorkIcon sx={{ fontSize: 64, color: "primary.main" }} />
              <Stack spacing={0.25} justifyContent="center" alignItems="center">
                <Text variant="body1">Get hired by local workplaces around you</Text>
                <Text variant="body2" align="center">
                  Once enabled, local workplaces will be able to view your profile, and contact you
                  with open job opportunities.
                </Text>
              </Stack>
            </Stack>
          )}

          {placementCandidate.status !== PlacementCandidateStatus.DISABLED && (
            <>
              <Stack spacing={2}>
                <Stack spacing={1}>
                  {placementCandidate.status !== PlacementCandidateStatus.ACTIVATED && (
                    <Text>
                      Upload your resume and select your preferences to activate your profile
                    </Text>
                  )}
                  <UploadCandidateResume
                    placementCandidate={placementCandidate}
                    onResumeUploaded={async () => {
                      // This is a temporary hack to ensure that the placement candidate is activated when resume and job preferences are present.
                      // The resume upload is being done directly via s3 and we do not have a way to update status on resume upload.
                      if (
                        isDefined(placementCandidate.jobTypesInterested) &&
                        placementCandidate.jobTypesInterested.length > 0 &&
                        isDefined(placementCandidate.shiftTypesInterested) &&
                        placementCandidate.shiftTypesInterested.length > 0
                      ) {
                        await updatePlacementCandidate({
                          placementCandidateId: placementCandidate.id,
                          workerId: worker.userId,
                          status: PlacementCandidateStatus.ACTIVATED,
                        });
                      }

                      await refetchPlacementCandidate();
                      showSuccessToast("Resume uploaded successfully");
                    }}
                  />

                  <JobPreferenceForm
                    initialJobTypes={placementCandidate.jobTypesInterested}
                    initialShiftTypes={placementCandidate.shiftTypesInterested}
                    initialAddressDetails={placementCandidate.preferredAreas?.[0]}
                    worker={worker}
                    onSave={async (data) => {
                      logEvent(APP_V2_APP_EVENTS.PLACEMENT_CANDIDATE_JOB_PREFERENCES_SUBMITTED, {
                        placementCandidateId: placementCandidate.id,
                        status: placementCandidate.status,
                        jobTypes: data.jobTypes,
                        shiftTypes: data.shiftTypes,
                        preferredAreas: data.addressObject,
                      });
                      await updatePlacementCandidate({
                        placementCandidateId: placementCandidate.id,
                        workerId: worker.userId,
                        jobTypesInterested: data.jobTypes,
                        shiftTypesInterested: data.shiftTypes,
                        preferredAreas: data.addressObject ? [data.addressObject] : undefined,
                      });
                      showSuccessToast("Your job preferences have been updated.");
                      await refetchPlacementCandidate();
                    }}
                  />
                </Stack>

                {!hasIntroductionUrl &&
                  ((isDefined(placementCandidate.jobTypesInterested) &&
                    placementCandidate.jobTypesInterested.length > 0) ||
                    (isDefined(placementCandidate.shiftTypesInterested) &&
                      placementCandidate.shiftTypesInterested.length > 0)) && (
                    <OnboardingPlacementCandidate
                      placementCandidateId={placementCandidate.id}
                      worker={worker}
                    />
                  )}
              </Stack>
              {hasIntroductionUrl && (
                <UploadedCandidateRequirements
                  placementCandidateId={placementCandidate.id}
                  introductionUrl={placementCandidate.introductionUrl}
                  worker={worker}
                />
              )}
            </>
          )}
        </Stack>

        {placementCandidate.status === PlacementCandidateStatus.PENDING && (
          <PendingPlacementCandidate />
        )}

        {placementCandidate.status === PlacementCandidateStatus.ACTIVATED && (
          <ActivatedPlacementCandidate hasIntroductionUrl={hasIntroductionUrl} />
        )}
      </Stack>
    </>
  );
}
