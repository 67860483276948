import { Text } from "@clipboard-health/ui-react";
import PendingActionsOutlinedIcon from "@mui/icons-material/PendingActionsOutlined";
import { Card, CardContent, Stack } from "@mui/material";

export function PendingPlacementCandidate() {
  return (
    <Card sx={{ position: "fixed", bottom: 1, left: 1, right: 1, zIndex: 1 }} variant="outlined">
      <CardContent
        sx={{
          "&.MuiCardContent-root": {
            padding: 2,
          },
        }}
      >
        <Stack direction="row" spacing={2} alignItems="center">
          <PendingActionsOutlinedIcon color="warning" fontSize="large" />
          <Text variant="body2" color="text.primary">
            Your profile is under review and will be visible to local workplaces looking to hire in
            your area shortly.
          </Text>
        </Stack>
      </CardContent>
    </Card>
  );
}
