import { ExternalLink, Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded";
import { Button, Stack } from "@mui/material";
import { AxiosError } from "axios";
import React from "react";

import { useToast } from "../../lib";
import { useUploadResume } from "../api/useUploadResume";
import { type PlacementCandidate } from "../types";

interface UploadCandidateResumeProps {
  placementCandidate: PlacementCandidate;
  onResumeUploaded: () => Promise<void>;
}

export function UploadCandidateResume(props: UploadCandidateResumeProps) {
  const { placementCandidate, onResumeUploaded } = props;
  const { showErrorToast } = useToast();
  const { mutateAsync: uploadResume, isLoading: isUploadingResume } = useUploadResume();
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const handleUploadResume = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isDefined(placementCandidate)) {
      return;
    }

    const file = event.target.files?.[0];
    if (!file) {
      showErrorToast("No file selected");
      return;
    }

    try {
      await uploadResume({
        file,
        placementCandidateId: placementCandidate.id,
      });
      await onResumeUploaded();
    } catch (error) {
      if (error instanceof AxiosError) {
        showErrorToast(error.message);
      } else {
        showErrorToast("Failed to upload your resume");
      }
    } finally {
      // Clear the input after successful or errored upload
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  return (
    <Stack>
      <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
        <Text variant="body1" color="text.primary">
          Resume
        </Text>

        <Stack direction="row" alignItems="center" spacing={1}>
          <Button
            component="label"
            startIcon={
              placementCandidate.resumeUrl ? <RefreshRoundedIcon fontSize="small" /> : undefined
            }
            variant="text"
            disabled={isUploadingResume}
            size="small"
          >
            {placementCandidate.resumeUrl ? "Re-upload" : "Upload resume"}
            <input
              ref={fileInputRef}
              type="file"
              accept=".pdf"
              style={{
                clip: "rect(0 0 0 0)",
                clipPath: "inset(50%)",
                height: 1,
                overflow: "hidden",
                position: "absolute",
                bottom: 0,
                left: 0,
                whiteSpace: "nowrap",
                width: 1,
              }}
              onChange={handleUploadResume}
            />
          </Button>

          {placementCandidate.resumeUrl ? (
            <ExternalLink to={placementCandidate.resumeUrl} variant="body1">
              View
            </ExternalLink>
          ) : undefined}
        </Stack>
      </Stack>
      <Text variant="body2">
        Upload your latest resume in PDF format to help us match you with the right job.
      </Text>
    </Stack>
  );
}
