import { Box, CircularProgress } from "@mui/material";

interface SwitchIconProps {
  isLoading: boolean;
}

export function SwitchIcon(props: SwitchIconProps) {
  const { isLoading } = props;
  return (
    <Box
      sx={{
        width: 20,
        height: 20,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
        backgroundColor: (theme) => theme.palette.background.default,
        boxShadow: (theme) => theme.shadows[1],
      }}
    >
      {isLoading ? (
        <CircularProgress size={14} color="inherit" thickness={6} />
      ) : (
        <Box
          sx={{
            width: 20,
            height: 20,
            borderRadius: "50%",
            backgroundColor: "currentColor",
          }}
        />
      )}
    </Box>
  );
}
